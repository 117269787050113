.signup {
  background: #1A2038;
  

  .signup-card {
    max-width: 800px;
    border-radius: 12px !important;
    img {
      width: 200px;
    }
  }

  .signup-card2 {
    max-width: 1200px;
    width: 90vw;
    border-radius: 12px !important;
  }
}
