.font22{
  font-size: 22px !important;
}

.justifyCcenter{
  justify-content: center;
}

.justifyCbetween {
  justify-content: space-between;
}

.justifyCaround {
  justify-content: space-around;
}

.justifyEnd {
  justify-content: flex-end;
}

.p4mb{
  padding: 4px;
}

.text-align-center{
  text-align: center;
}

.text-lined{
  text-decoration: line-through !important;
  text-decoration-color: black !important;
}

.text-underLined{
  text-decoration: underline !important;
  //text-decoration-color: black !important;
}

.pos-absolute{
  position: absolute;
}

// drop zone is here

.dropzone {
  margin-bottom: 20px;
}

.dropzone-card{
  position: relative;
}

.cardImageList{
  max-height: 150px;
}

.dropzone-file-btn{
  width: 200px;
  height: 200px;
  border: 2px dashed gray;
  margin: 0 auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}

.dropzone-file-btn-last-child{
  margin-bottom: 0;
}

.dropzonebtn{
  width: 200px;
  align-self: center;
}

// backdrop
.back-drop{
  z-index: 99 !important;
  color: #fff;
}
// Table

.maxTableHeight{
  max-height: 440px;
  @include media(767) {
    max-height: 64vh;
  }
}

//percent

.percent{
  position: absolute;
  left: 49%;
  top: 50%;
  z-index: 99;
  font-size: xx-large;
  color: white;
}

//btn

.btn-primary{
  padding: 10px 15px 10px 15px;
  background-color: #43a047 !important;
  color: white !important;
  border-radius: 10px;
  cursor: pointer;
  //transition: 0.35s;
  border: 0;
}

.btn-primary:hover{
  background-color: #1b5e20 !important;
}

.bgPrimary{
  background-color: #43a047 !important;
}

.hoverErrorBg{
  background-color: #f44336 !important;
  color: white !important;
  transition: 0.5s;
}

.hoverErrorBg:hover{
  background-color: darkred !important;
}

.paymentImage {
  width: 500px !important;
}

.madrakCard{
  width: 100px;
  height: 100px;
  //padding: 10px;
  display: inline-block;
  margin-right: 16px;
  margin-left: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
  transition: 1s;
  border-radius: 8px;
}

.madrakCard:hover{
  opacity:0.5;
}

//forum

.owner{
  border-radius: 16px 16px 0 16px;
  padding: 8px;
}

.not-owner{
  border-radius: 16px 16px 16px 0;
  padding: 8px;
}

.opacity-75{
  opacity: 0.75;
}

.wordWrap{
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  /* Adds a hyphen where the word breaks */
  -webkit-hyphens: auto !important;
  -ms-hyphens: auto !important;
  -moz-hyphens: auto !important;
  hyphens: auto !important;
}

.ql-align-right{
  text-align: right !important;
}

.ql-direction-rtl{
  text-align: right !important;
}