.mini-cart {
  width: $sidenav-width;

  .mini-cart__item {
    transition: background 300ms ease;
    &:hover {
      background: $light-gray;
    }
    img {
      width: 80px;
    }
  }
}
.cart__topbar {
  height: $topbar-height;
  box-shadow: $elevation-z6;
}